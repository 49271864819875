import React, { useMemo } from "react";
import { useFormikContext } from "formik";
import * as Yup from "yup";
import gql from "graphql-tag";
import * as R from "ramda";
import { FormField, Select, Input } from "@jasperlabs/jux-next";
import { countryIds } from "constants/countries";
import { useCallingCodesQuery } from "./index.generated";
import { getNestedObjectByKey } from "../form/utils";

gql`
	query CallingCodes {
		countries {
			id
			name
			callingCode
		}
	}
`;

const make = ({
	name,
	callingCode,
	id,
}: {
	name: string;
	callingCode: string;
	id: string;
}) => ({
	label: `${name} (+${callingCode})`,
	value: callingCode,
	id,
});

const mapCountriesToOptions = (countries: any) => {
	const nz = countries.find((country: any) => country.id === countryIds.NZ);
	const au = countries.find((country: any) => country.id === countryIds.AU);
	const pitcairnIslands = countries.find(
		(country: any) => country?.id === "612",
	);

	// @ts-ignore
	const filterSortMap = R.compose(
		R.map(make),
		R.when(() => nz, R.prepend(nz)),
		R.when(() => au, R.prepend(au)),
		R.sortBy(R.prop("name")),
		// @ts-ignore
		R.filter(R.prop("callingCode")),
		R.without([nz, au, pitcairnIslands]),
	);

	return filterSortMap(countries);
};

export const phoneNumberSchema = Yup.object().shape({
	callingCode: Yup.string().required("Calling Code is required"),
	phoneNumber: Yup.string()
		.required("Phone number is required")
		.min(6, "Please enter a valid phone number"),
	completePhoneNumber: Yup.string().when(["callingCode", "phoneNumber"], {
		// A phone number is valid when there is both calling code and phone number
		is: R.xor,
		then: (schema) => schema.required("Please enter a valid phone number"),
		otherwise: (schema) => schema,
	}),
});

export const phoneNumberInitialValues = {
	callingCode: "64",
	phoneNumber: "",
};

type Props = {
	groupKey: string;
	label?: string;
	isDisabled?: boolean;
	hint?: React.ReactNode;
};

const PhoneNumberForm = ({
	label,
	groupKey,
	isDisabled: isFormDisabled,
	hint,
}: Props) => {
	const { data, loading } = useCallingCodesQuery();

	const mappedCountries = useMemo(() => {
		const countries = data?.countries || [];
		return mapCountriesToOptions(countries);
	}, [data?.countries]);

	const { values, touched, errors, setFieldValue, handleChange, handleBlur } =
		useFormikContext<any>();

	const phoneNumberToched = getNestedObjectByKey(touched, groupKey) || {};
	const phoneNumberErrors = getNestedObjectByKey(errors, groupKey) || {};
	const phoneNumberValues = getNestedObjectByKey(values, groupKey) || {};

	const isInvalid =
		Boolean(phoneNumberToched.callingCode && phoneNumberToched.phoneNumber) &&
		Boolean(
			phoneNumberErrors.callingCode ||
				phoneNumberErrors.phoneNumber ||
				phoneNumberErrors.completePhoneNumber,
		);

	const isDisabled = isFormDisabled || loading;
	return (
		<FormField
			id="phone-number-form"
			label={label}
			hasError={isInvalid}
			error={
				isInvalid
					? phoneNumberErrors.callingCode ||
					  phoneNumberErrors.phoneNumber ||
					  phoneNumberErrors.completePhoneNumber
					: null
			}
			hint={hint}
			disabled={isDisabled}
		>
			<Select
				data-testid="phone-number-calling-code"
				className="w-32"
				id="callingCode"
				name={`${groupKey}.callingCode`}
				value={phoneNumberValues.callingCode}
				formatSelectedValue={(callingCode) => callingCode && `+${callingCode}`}
				onValueChange={(callingCode) =>
					callingCode && setFieldValue(`${groupKey}.callingCode`, callingCode)
				}
				onBlur={handleBlur(`${groupKey}.callingCode`)}
			>
				{mappedCountries.map(({ label: optionLabel, value, id: optionId }) => (
					<>
						<Select.Item key={optionId} value={value}>
							{optionLabel}
						</Select.Item>
						{optionId === countryIds.AU && <Select.Separator />}
					</>
				))}
			</Select>
			<Input
				type="tel"
				id="phoneNumber"
				name={`${groupKey}.phoneNumber`}
				data-testid="phone-number-input"
				value={phoneNumberValues.phoneNumber}
				onChange={handleChange(`${groupKey}.phoneNumber`)}
				onBlur={handleBlur(`${groupKey}.phoneNumber`)}
			/>
		</FormField>
	);
};

export default PhoneNumberForm;
